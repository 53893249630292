import React from 'react'
import { injectIntl, Link, FormattedMessage } from 'gatsby-plugin-intl'
// import Layout from '../components/layout'
import SEO from '../components/seo'

const Service = ({ intl }) => (
  <>
    <SEO title={intl.formatMessage({ id: 'pages.service.title' })} />
    <div
      style={{
        maxWidth: `800px`,
        margin: `0 auto`,
      }}
    >
      <form>
        <div className='interfaceRow justifyCenter'>
          <Link to='/filter-status/'>
            <FormattedMessage id='pages.service.filterstatus' />
          </Link>
        </div>
        <div className='interfaceRow justifyCenter'>
          <Link to='/maintenance/'>
            <FormattedMessage id='pages.service.maintenance' />
          </Link>
        </div>
        <div className='interfaceRow justifyCenter'>
          <Link to='/operating-hours/'>
            <FormattedMessage id='pages.service.operatinghourscounter' />
          </Link>
        </div>
        <div className='interfaceRow justifyCenter'>
          <Link to='/remote-support/'>
            <FormattedMessage id='pages.service.remotemaintenanceassistant' />
          </Link>
        </div>
        <div className='interfaceRow justifyCenter'>
          <Link to='/notifications/'>
            <FormattedMessage id='pages.service.notifications' />
          </Link>
        </div>
      </form>
    </div>
  </>
)

export default injectIntl(Service)
